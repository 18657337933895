import React, { useState } from "react"
import "./philosophy-header-mobile.scss"
import { PlusIcon } from "../elements/svg-elements"

import { PhilosophyHeaderMobileProps } from "../../types/components"
import Image from "../shared/img"

const PhilosophyHeaderMobile: React.FC<PhilosophyHeaderMobileProps> = ({
  views,
}) => {
  const [active, setActive] = useState<boolean>(false)
  return (
    <div className="philosophy-mobile-item">
      {views.imageMobile && <Image imgProps={views.imageMobile} />}
      <div
        className={
          active
            ? "philosophy-mobile-item-accordion active"
            : "philosophy-mobile-item-accordion"
        }
        aria-expanded={active}
        onClick={() => setActive(prev => !prev)}
      >
        <div className="philosophy-mobile-item-button">
          <PlusIcon />
          {views.title}
        </div>
        {active && (
          <div
            className="accordion__description container--xl"
            dangerouslySetInnerHTML={{ __html: views.content }}
          />
        )}
      </div>
    </div>
  )
}

export default PhilosophyHeaderMobile
