import React, { useEffect, useRef, useState } from "react"
import "./philosophy-header.scss"
import { CircleArrow } from "../elements/svg-elements"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import data from "../../assets/lotties/data.json"
import lottie from "lottie-web"
import { PhilosophyHeaderProps } from "../../types/components"
import { TabProps } from "../../types/shared-singulars"
import { isBrowser, isDocument } from "../../utils/constants"

gsap.registerPlugin(ScrollTrigger)

const PhilosophyHeader: React.FC<PhilosophyHeaderProps> = ({
  views,
  title,
}) => {
  const [itemWidth, setItemWidth] = useState<number>(33)
  const [contentId, setContentId] = useState<number>(0)
  const containerRef = useRef<any>()
  const lottieRef = useRef<any>()

  function animateElements(progress: number, id: number) {
    setItemWidth(progress)
    setContentId(id)
  }

  if (!isDocument && isBrowser()) return <></>

  function scrollLottie() {
    let timeObj = { currentFrame: 0 }

    let anim = lottie.loadAnimation({
      container: lottieRef.current,
      animationData: data,
      renderer: "svg",
      loop: false,
      autoplay: false,
    })

    ScrollTrigger.create({
      trigger: lottieRef.current,
      pin: "#container",
      scrub: 1,
      start: "top 225px",
      end: () => `+=${containerRef.current?.offsetWidth}`,
      onUpdate: self => {
        let progress = Math.floor(self.progress * (anim.totalFrames - 1))
        if (progress <= 50) {
          animateElements(33, 0)
        } else if (progress <= 100) {
          animateElements(67, 1)
        } else {
          animateElements(100, 2)
        }

        gsap.to(timeObj, {
          duration: 1,
          currentFrame: Math.floor(self.progress * (anim.totalFrames - 1)),
          onUpdate: () => {
            anim.goToAndStop(timeObj.currentFrame, true)
          },
          ease: "expo",
        })
      },
    })
  }

  const goToTab = (tabNumber: number) => {
    let yHeight = Number((containerRef.current?.offsetWidth / 3).toFixed(0))
    if (tabNumber === 0) {
      window.scrollTo(0, yHeight * tabNumber)
    } else {
      window.scrollTo(0, yHeight * (tabNumber + 1) - 10)
    }
  }

  useEffect(() => {
    if (containerRef.current.offsetWidth > 1023) {
      scrollLottie()
    }

    return () => {
      ScrollTrigger.disable()
    }
  }, [])

  return (
    <div className="philosophy-header" ref={containerRef} id="container">
      <div className="container--xl philosophy-header__title">
        <h1>{title}</h1>
      </div>
      <ul className="philosophy-header__menu">
        <span
          className="philosophy-header__menu__layer"
          style={{ width: `${itemWidth}%` }}
        />
        {views.map((view: TabProps, index: number) => (
          <li key={index} onClick={() => goToTab(index)}>
            <CircleArrow /> {view.title}
          </li>
        ))}
      </ul>
      <div className="philosophy-header__view">
        <div className="philosophy-header__view__lottie" ref={lottieRef} />
        {views.map((view: TabProps, index: number) => (
          <div
            key={index}
            className={
              contentId === index
                ? "philosophy-header__view__content show"
                : "philosophy-header__view__content"
            }
          >
            <div className="philosophy-header__view__content__pagination">
              <span className="philosophy-header__view__content__pagination__red-dot" />
              <span className="philosophy-header__view__content__pagination__numbers">
                {index + 1}/{views.length}
              </span>
            </div>
            <h2>{view.title}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: view.content }}
              className="philosophy-header__view__content__text"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PhilosophyHeader
