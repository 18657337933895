import React, { useMemo } from "react"
import { graphql, PageProps } from "gatsby"
import PageLayout from "../layouts/page-layout"
import { PhilosophyPageProps } from "../types/pages"
import PhilosophyHeader from "../components/philosophy/philosophy-header"
import Accordion from "../components/shared/accordion"
import { AccordionProps, TabProps } from "../types/shared-singulars"
import PhilosophyHeaderMobile from "../components/philosophy/philosophy-header-mobile"

const Philosophy: React.FC<PageProps<PhilosophyPageProps>> = ({ data }) => {
  const { philosophyAcc, tabs } = data?.PAGE_CONTENT?.philosophyOptions

  const accordions = useMemo(() => {
    return philosophyAcc?.map(acc => {
      return {
        title: acc.title,
        description: acc.description,
      }
    })
  }, [JSON.stringify(philosophyAcc)])

  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <div className="philosopy-mobile">
        {tabs.map((tab: TabProps, index: number) => (
          <PhilosophyHeaderMobile views={tab} key={index} />
        ))}
      </div>
      <PhilosophyHeader views={tabs} title={data?.PAGE_METADATA?.title} />

      <div className="accordion--wrapper">
        {accordions &&
          accordions.map((accordion: AccordionProps, index: number) => (
            <Accordion
              key={index}
              titileAccordions={accordion.title}
              descriptionAccordions={accordion.description}
            />
          ))}
      </div>
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      slug
      title
      link
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      philosophyOptions {
        philosophyAcc {
          description
          title
        }
        tabs {
          imageMobile {
            altText
            sizes
            sourceUrl
            srcSet
          }
          content
          title
        }
      }
    }
  }
`

export default Philosophy
