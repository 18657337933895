import React, { memo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ImgProps } from "../../types/shared-singulars"

type Props = {
  className?: string
  imgProps: ImgProps
  delay?: string | number
}

const Img: React.FC<Props> = ({ className, imgProps, delay }) => {
  const { IMAGE } = useStaticQuery(graphql`
    query {
      IMAGE: file(relativePath: { eq: "missing-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (imgProps?.srcSet || imgProps?.sourceUrl) {
    return (
      <img
        className={className}
        alt={imgProps.altText}
        srcSet={imgProps.sourceUrl}
        sizes={imgProps.sizes}
        data-aos={delay ? "fade-in" : null}
        data-aos-delay={delay}
        data-aos-easing={delay ? "ease-out" : null}
      />
    )
  } else {
    return (
      <div
        className="missing-image"
        style={{ backgroundImage: `url(${IMAGE.childImageSharp.fluid.src})` }}
      />
    )
  }
}

export default memo(Img)
