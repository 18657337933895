import React, { memo, useState } from "react"
import "./accordion.scss"
import { AccordionProps } from "../../types/shared-singulars"
import { CircleArrow } from "../elements/svg-elements"

const Accordion: React.FC<AccordionProps> = ({
  titileAccordions,
  descriptionAccordions,
  children,
}) => {
  const [accordion, setAccordion] = useState<boolean>(false)

  return (
    <div
      className={accordion ? `accordion openned` : `accordion`}
      aria-expanded={accordion}
    >
      <div className="accordion__wrapper container--xl">
        <div
          className="accordion__title"
          onClick={() => setAccordion(!accordion)}
        >
          <CircleArrow />
          <span>{titileAccordions}</span>
        </div>
        {accordion && (
          <>
            <div
              className="accordion__description"
              dangerouslySetInnerHTML={{ __html: descriptionAccordions! }}
            />
            {children}
          </>
        )}
      </div>
    </div>
  )
}

export default memo(Accordion)
